<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Money'"
      :ActiveMenu="'collectionreport'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Collection Report"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Money"
        breadcrumbitem3="Collection Report"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

     

      <!-- begin row -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">Collection Statistics</h4>
            </div>
            <!-- end panel-heading -->

          

            <!-- begin panel-body -->
            <div class="panel-body">
              <!-- begin nav-pills -->
              <ul class="nav nav-pills">
                <li class="nav-items">
                  <a href="#nav-pills-tab-1" data-toggle="tab" id="byP" class="nav-link active">
                    <span class="d-sm-none">Payments</span>
                    <span class="d-sm-block d-none">Payments</span>
                  </a>
                </li>
                <li class="nav-items">
                  <a href="#nav-pills-tab-2" data-toggle="tab" id="byV" class="nav-link">
                    <span class="d-sm-none">By Vehicles</span>
                    <span class="d-sm-block d-none">Arrears By Vehicles</span>
                  </a>
                </li>
                <li class="nav-items">
                  <a href="#nav-pills-tab-3" data-toggle="tab" id="byO" class="nav-link">
                    <span class="d-sm-none">By Operators</span>
                    <span class="d-sm-block d-none">Arrears By Operators</span>
                  </a>
                </li>
                <li class="nav-items">
                  <a href="#nav-pills-tab-4" data-toggle="tab" id="byC" class="nav-link">
                    <span class="d-sm-none">By Collectors</span>
                    <span class="d-sm-block d-none">Arrears By Collectors
              <div class="spinner-border spinner-border-lg text-info" v-if="!dataloadedABC"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
                    </span>
                  </a>
                </li>
                 <li class="nav-items">
                  <a href="#nav-pills-tab-5" data-toggle="tab" id="byBP" class="nav-link">
                    <span class="d-sm-none">TAMS Billing</span>
                    <span class="d-sm-block d-none">TAMS Billing</span>
                  </a>
                </li>
              </ul>
              <!-- end nav-pills -->
              <!-- begin tab-content -->
              <div class="tab-content">
                <!-- begin tab-pane -->
                <div class="tab-pane fade active show" id="nav-pills-tab-1">
                  <h3 class="m-t-10"></h3>
                  <div class="form-group row">
                    <label class="col-lg-2 col-form-label">Enter Payment Date</label>
                    <div class="col-lg-3">
                      <div id="advance-daterange" class="btn btn-default btn-block text-left">
                        <i class="fa fa-caret-down pull-right m-t-2"></i>
                        <span></span>
                      </div>
                    </div>
                  </div>
                     <div v-if="!dataloaded" class="btn-row">
            <div class="text-center">
              <div class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            </div>

                  <table id="data-table-combine-payments" class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th width="1%" data-orderable="false"></th>
                        <th class="text-nowrap">Operator/Taxi</th>
                        <th class="text-nowrap">Amount</th>
                        <th class="text-nowrap">Captured By</th>
                        <th class="text-nowrap">Captured Date</th>
                        <th class="text-nowrap">Status</th>
                        <th class="text-nowrap hidden-print">Location</th>
                         <th class="text-nowrap hidden-print">Voice Note</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(item,index) in GETPAYMENTHISTORY"
                        v-bind:key="item._id"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                      >
                        <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                        <td>{{item.entityname}}</td>
                        <td>{{item._amount | currency}}</td>
                        <td>{{item.captured_name}}</td>
                        <td>{{item.captureddate | formatToShortDateWithTime }}</td>
                        <td>{{item.receiptstatus}}</td>
                        <td class="with-btn valign-middle" nowrap>
                          <button
                          v-if="item.geolocation"
                            class="btn btn-sm btn-primary width-120 m-r-2"
                              @click="viewLocation(item.geolocation)"
                            >View Location</button>
            
                        </td>
                        <td class="valign-middle" nowrap>
                          <audio style="width: 100px;height: 20px"  v-if="item.hasvoicenotes" controls>
                          <source :src="item.voicenotes[0].data" type="audio/mpeg">
                              Your browser does not support the audio element.
                          </audio>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- end tab-pane -->
                <!-- begin tab-pane -->
                <div class="tab-pane fade" id="nav-pills-tab-2">
                  <h3 class="m-t-10"></h3>
                  <table id="data-table-combine-vehicles" class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th width="1%" data-orderable="false"></th>
                        <th class="text-nowrap">Taxi Reg No</th>
                        <th class="text-nowrap">Operator Name</th>
                        <th class="text-nowrap">Id Number</th>
                        <th class="text-nowrap">Operator Mobile</th>
                        <th class="text-nowrap">Outstanding Balance</th>
                        <th class="text-nowrap">Status</th>
                        <th class="text-nowrap hidden-print">Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(item,index) in GETARREARSBYVEHICLES"
                        v-bind:key="item._id"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                      >
                        <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                        <td>{{item.regnumber}}</td>
                        <td>{{item.taxioperator_name}}</td>
                        <td>{{item.idnumber}}</td>
                        <td>{{item.mobilenumber}}</td>
                        <td>{{item.availablebalance | currency}}</td>
                        <td>{{item.accountstatus}}</td>
                        <td class="with-btn valign-middle hidden-print" nowrap>
                          <router-link
                            :to="{ name: 'taxivehicleprofile', params: { id: item._taxi_vehicle_id }}"
                            class="btn btn-sm btn-primary width-120 m-r-2"
                          >View Profile</router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- end tab-pane -->
                <!-- begin tab-pane -->
                <div class="tab-pane fade" id="nav-pills-tab-3">
                  <h3 class="m-t-10"></h3>
                  <table id="data-table-combine-operators" class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th width="1%" data-orderable="false"></th>
                        <th class="text-nowrap">Operator Name</th>
                        <th class="text-nowrap">Id Number</th>
                        <th class="text-nowrap">Mobile No</th>
                        <th class="text-nowrap">Outstanding Amount</th>
                        <th class="text-nowrap">Number Of Taxis</th>
                        <th class="text-nowrap hidden-print">Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(item,index) in GETARREARSBYOPERATORS"
                        v-bind:key="item._id"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                      >
                        <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                        <td>{{item.taxioperator_name}}</td>
                        <td>{{item.idnumber}}</td>
                        <td>{{item.mobilenumber}}</td>
                        <td>{{item.outstandingamount | currency}}</td>
                        <td>{{item.current_number_vehicles}}</td>
                        <td class="with-btn valign-middle" nowrap>
                          <router-link
                            :to="{ name: 'taxioperatorprofile', params: { id: item._id }}"
                            class="btn btn-sm btn-primary width-120 m-r-2"
                          >View Profile</router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- end tab-pane -->
                <!-- begin tab-pane -->
                <div class="tab-pane fade" id="nav-pills-tab-4">
                  <h3 class="m-t-10"></h3>
                  <table id="data-table-combine-collector" class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th width="1%" data-orderable="false"></th>
                        <th class="text-nowrap">Collectors Name</th>
                        <th class="text-nowrap">Monthly Expected</th>
                        <th class="text-nowrap">Monthly Collected</th>
                        <th class="text-nowrap">Monthly Arrears</th>
                        <th class="text-nowrap">Weekly Expected</th>
                        <th class="text-nowrap">Weekly Collected</th>
                        <th class="text-nowrap">Weekly Arrears</th>
                        <th class="text-nowrap hidden-print">Collection Week</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(item,index) in GETARREARSBYCOLLECTORS"
                        v-bind:key="item._id"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                      >
                        <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                        <td>{{item.collector_name}}</td>
                        <td>{{item.month_expected | newcurrencywithSymbol}}</td>
                        <td>{{item.month_collected | newcurrencywithSymbol}}</td>
                        <td>{{item.month_arrears | newcurrencywithSymbol}}</td>
                        <td>{{item.week_expected | newcurrencywithSymbol}}</td>
                        <td>{{item.week_collected | newcurrencywithSymbol}}</td>
                        <td>{{item.week_arrears | newcurrencywithSymbol}}</td>
                        <td class="with-btn valign-middle" nowrap>
                          <router-link
                            :to="{ name: 'collectionReportPreviousWeekDetails', params: { id: item._id }}"
                            class="btn btn-sm btn-yellow width-120 m-r-2"
                          >Last Week</router-link>
                          <router-link
                            :to="{ name: 'collectionReportDetails', params: { id: item._id }}"
                            class="btn btn-sm btn-primary width-120 m-r-2"
                          >This Week</router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- end tab-pane -->
                 <!-- begin tab-pane -->
                <div class="tab-pane fade" id="nav-pills-tab-5">
                  <h3 class="m-t-10"></h3>
                   <div class="row">
              <!-- begin col-3 -->
              <div class="col-lg-3 col-md-6">
                <div class="widget widget-stats bg-white text-inverse">
                  <div class="stats-icon stats-icon-square bg-gradient-blue">
                    <i class="ion-ios-car"></i>
                  </div>
                  <div class="stats-content">
                    <div class="stats-title">BILLABLE VEHICLES</div>
                    <div class="stats-number">{{ BILLABLEVEHICLES}}</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="widget widget-stats bg-white text-inverse">
                  <div class="stats-icon stats-icon-square bg-gradient-blue">
                    <i class="ion-ios-car"></i>
                  </div>
                  <div class="stats-content">
                    <div class="stats-title">EXTRA BILLABLE VEHICLES</div>
                    <div class="stats-number">{{ EXTRABILLABLEVEHICLES}}</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="widget widget-stats bg-white text-inverse">
                  <div class="stats-icon stats-icon-square bg-gradient-blue">
                    <i class="ion-ios-car"></i>
                  </div>
                  <div class="stats-content">
                    <div class="stats-title">TOTAL BILLABLE VEHICLES</div>
                    <div class="stats-number">{{ TOTALBILLINGVEHICLEBAL}}</div>
                  </div>
                </div>
              </div>
              </div>

              


                  <div class="form-group row">
                    <label class="col-lg-2 col-form-label">Enter Billing Week</label>
                    <div class="col-lg-3">
                      <div id="advance-daterange-billpaymentssummary" class="btn btn-default btn-block text-left">
                        <i class="fa fa-caret-down pull-right m-t-2"></i>
                        <span></span>
                      </div>
                    </div>
                  </div>
                     <div v-if="!dataloaded" class="btn-row">
            <div class="text-center">
              <div class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            </div>

                  <table id="data-table-combine-billpayments" class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th width="1%" data-orderable="false"></th>
                        <th class="text-nowrap">Taxi</th>
                        <th class="text-nowrap">Amount</th>
                        <th class="text-nowrap">Num Of Weeks</th>
                        <!-- <th class="text-nowrap">Num Of Passangers</th>
                        <th class="text-nowrap">Total Fare Generated</th> -->
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(item,index) in GETBILLPAYMENTHISTORY"
                        v-bind:key="item._id"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                      >
                        <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                        <td>{{item.licencenumber}}</td>
                        <td>{{item.total_transaction_amount | newcurrency}}</td>
                        <td>{{item.numofinvoices}}</td>
                        <!-- <td>{{item.numofpassagers}}</td>
                        <td>{{item.totaltaxifare | newcurrencywithSymbol}}</td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- end tab-pane -->
              </div>
              <!-- end tab-content -->
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>

       <!-- #modal-message start -->
      <div class="modal fade" id="disputesModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="text-center">
              <h4 class="modal-title"></h4>
            </div>
            <div class="modal-body">
              <div class="height-450">
                    <div style="height: 100%;width: 100%;" id="mapid"></div>
              </div>
            </div>
            <div class="text-center m-b-5">
              <a href="javascript:;" class="btn btn-danger m-5 btn-lg" data-dismiss="modal">Close</a>
            </div>
          </div>
        </div>
      </div>
      <!-- #modal-message end -->

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { setTimeout } from "timers";
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("payments");

export default {
  name: "ManageVehicles",
  data: function() {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      context: this,
      addedsuccess: undefined,
      errormessage: undefined,
      id: this.$route.query.id,
      skip: 0,
      length: 10000,
      startDate: {},
      endDate: {},
      paymentsloaded: false,
      GETPAYMENTHISTORY: [],
      GETBILLPAYMENTHISTORY: [],
      EXTRABILLABLEVEHICLESAMOUNT: 0,
      BILLABLEVEHICLESAMOUNT: 0,
      arearsvehiclesloaded: false,
      arrearsoperatorsloaded: false,
      arrearscollectorsloaded: false,
      COLLECTORARREARS: [],
      GETARREARSBYOPERATORS:[],
      GETARREARSBYVEHICLES:[],
      GETARREARSBYCOLLECTORS:[],
      maploaded: false,
      mymap:null,
      marker:null,
      tileLayer: null,
      dataloaded: false,
      dataloadedABC:true
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters({
    }),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
    BILLABLEVEHICLES: function(){

      //

      let sum = 0;
      this.GETBILLPAYMENTHISTORY.forEach(function(item) {
        if(item.numofinvoices === 1)
         sum += (parseFloat(item.numofinvoices));
      });

      this.BILLABLEVEHICLESAMOUNT = sum;

     return sum;
   },
   EXTRABILLABLEVEHICLES: function(){

      //

      let sum = 0;
      this.GETBILLPAYMENTHISTORY.forEach(function(item) {
        if(item.numofinvoices == 2)
         sum += (parseFloat(item.numofinvoices));
      });

      this.EXTRABILLABLEVEHICLESAMOUNT = sum;

     return sum;
   },
   TOTALBILLINGVEHICLEBAL: function(){

     return this.BILLABLEVEHICLESAMOUNT + this.EXTRABILLABLEVEHICLESAMOUNT;
   },
  },
  methods: {
    ...mapActions([
      "generatepaymentHistory",
      "generatepayment4billingHistory",
      "generateArrearsByVehicles",
      "generateArrearsByOperators",
      "generateArrearsByCollector"
    ]),
    viewLocation: function(geolocation) {
      
      $("#disputesModal").modal("show");

       
      
      if(!this.maploaded)
      {

        this.maploaded = true;

        

            setTimeout(function(context){

            context.mymap = L.map('mapid').setView([geolocation.latitude,geolocation.longitude], 16);

              context.tileLayer = L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFueW93YSIsImEiOiJjbHlvczVqdjUwaGNjMmpzZmFnN2h4djZkIn0.ZjB9zzv5VjI1EsQY4VRsUA', {
                maxZoom: 18,
                attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, ' +
                  'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                id: 'mapbox/streets-v11',
                tileSize: 512,
                zoomOffset: -1
              }).addTo(context.mymap);

              

                context.tileLayer.on("load",function() { 

                if(!context.marker)
                  context.marker = L.marker([geolocation.latitude, geolocation.longitude]).addTo(context.mymap);

                });


              }, 600,this);
        
      }
      else
      {

        this.marker.setLatLng(L.latLng(geolocation.latitude, geolocation.longitude))
        this.mymap.panTo(L.latLng(geolocation.latitude, geolocation.longitude));

      }


      
    },
    loadgeneratepaymentHistory: function(context) {

      

        var payload = {
          taxiassociationsId: context.taxiassociationid,
          startDate: context.$Toolkit.ConvertToServerDate(context.startDate),
          endDate: context.$Toolkit.ConvertToServerDate(context.endDate),
          skip: context.skip,
          length: context.length
        };

        const router = context.$router;

        context.dataloaded = false;

        context
          .generatepaymentHistory({ router, payload })
          .then(parsedResultOP => {
            if (parsedResultOP.status) {
              console.log('Payment results: ',parsedResultOP.data);
              if (parsedResultOP.data.length > 0) {


                if(context.GETPAYMENTHISTORY)
                  $("#data-table-combine-payments").DataTable().destroy();

                  context.GETPAYMENTHISTORY = parsedResultOP.data;
              }
              
            }
            //context.$wait.end("generatestatement");
          })
          .catch(error => {
            //context.$wait.end("generatestatement");
          }).finally(() => {
           
            var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: false,
                    autoFill: false,
                    colReorder: true,
                    keys: false,
                    rowReorder: false,
                    select: false
                  };

                  if ($(window).width() <= 767) {
                    options.rowReorder = false;
                    options.colReorder = false;
                    options.autoFill = false;
                  }

                 
 	                $("#data-table-combine-payments").DataTable(options);

                  context.paymentsloaded = true;
                  context.dataloaded = true;

          });
    },loadgeneratebillpaymentHistory: function(context) {

      

        var payload = {
          taxiassociationsId: context.taxiassociationid,
          startDate: context.$Toolkit.ConvertToServerDate(context.startDate),
          endDate: context.$Toolkit.ConvertToServerDate(context.endDate),
          skip: context.skip,
          length: context.length
        };

        const router = context.$router;

        context.dataloaded = false;

        context
          .generatepayment4billingHistory({ router, payload })
          .then(parsedResultOP => {
            if (parsedResultOP.status) {
              //console.log('Payment results: ',parsedResultOP.data);
              if (parsedResultOP.data.length > 0) {


                if(context.GETBILLPAYMENTHISTORY)
                  $("#data-table-combine-billpayments").DataTable().destroy();

                  context.GETBILLPAYMENTHISTORY = parsedResultOP.data;
              }
              
            }
            //context.$wait.end("generatestatement");
          })
          .catch(error => {
            //context.$wait.end("generatestatement");
          }).finally(() => {
           
            var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: false,
                    autoFill: false,
                    colReorder: true,
                    keys: false,
                    rowReorder: false,
                    select: false
                  };

                  if ($(window).width() <= 767) {
                    options.rowReorder = false;
                    options.colReorder = false;
                    options.autoFill = false;
                  }

                 
 	                $("#data-table-combine-billpayments").DataTable(options);

                  context.paymentsloaded = true;
                  context.dataloaded = true;

          });
    },
  },
  
  beforeMount: function() {},
  mounted: function() {
    
    //this.getbyid(this.context, this.taxiassociationid);

    App.init();

    $(".nav-link").on("click", function() {
      const context = $("#advance-daterange span").data("context");

      const router = context.$router;

      var payload = {
        taxiassociationsId: context.taxiassociationid,
        skip: context.skip,
        length: context.length
      };

      var id = context.taxiassociationid;

      context.dataloaded = false;

      if ($(this).attr("id") === "byV") {
        context
          .generateArrearsByVehicles({ router, payload })
          .then(parsedResultOP => {
            if (parsedResultOP.status) {
              if (parsedResultOP.data.length > 0) {
                  
                  context.GETARREARSBYVEHICLES = parsedResultOP.data

                }
            }
            //context.$wait.end("generatestatement");
          })
          .catch(error => {
            //context.$wait.end("generatestatement");
          }).finally(() => {
            
            var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: false,
                    autoFill: false,
                    colReorder: true,
                    keys: false,
                    rowReorder: false,
                    select: false
                  };

                  if ($(window).width() <= 767) {
                    options.rowReorder = false;
                    options.colReorder = false;
                    options.autoFill = false;
                  }

                  if(!context.arearsvehiclesloaded)
                  {
                    
 	                  $("#data-table-combine-vehicles").DataTable(options);
                 
                  }
                 
                  context.arearsvehiclesloaded = true;
                  context.dataloaded = true;

          });
      } else if ($(this).attr("id") === "byO") {
        const router = context.$router;

        context
          .generateArrearsByOperators({ router, payload })
          .then(parsedResultOP => {
            if (parsedResultOP.status) {
              if (parsedResultOP.data.length > 0) {
                  context.GETARREARSBYOPERATORS = parsedResultOP.data
                }
              
            }
            //context.$wait.end("generatestatement");
          })
          .catch(error => {
            //context.$wait.end("generatestatement");
          }).finally(() => {
            
            var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: false,
                    autoFill: false,
                    colReorder: true,
                    keys: false,
                    rowReorder: false,
                    select: false
                  };

                  if ($(window).width() <= 767) {
                    options.rowReorder = false;
                    options.colReorder = false;
                    options.autoFill = false;
                  }

                  if(!context.arrearsoperatorsloaded)
                  {
 	                  $("#data-table-combine-operators").DataTable(options);
                  }

                  context.arrearsoperatorsloaded = true;
                  context.dataloaded = true;

          });
      }
      else if ($(this).attr("id") === "byC") {
        const router = context.$router;

        

        context.dataloaded = false;
        context.dataloadedABC = false;
        
        
        context.generateArrearsByCollector({
              router: router,
              id: id
            })
        .then(parsedResultOP => {
            if (parsedResultOP.status) {
              if (parsedResultOP.data.length > 0) {
                  context.GETARREARSBYCOLLECTORS = parsedResultOP.data
                  
            }
            }
          })
          .catch(error => {
            
            //context.$wait.end("generatestatement");
          }).finally(()=> {
            
            var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: false,
                    autoFill: false,
                    colReorder: true,
                    keys: false,
                    rowReorder: false,
                    select: false
                  };

                  if ($(window).width() <= 767) {
                    options.rowReorder = false;
                    options.colReorder = false;
                    options.autoFill = false;
                  }

                  if(!context.arrearscollectorsloaded)
                  {
 	                  $("#data-table-combine-collector").DataTable(options);
                  }

                  context.arrearscollectorsloaded = true;
                  context.dataloaded = true;
                  context.dataloadedABC = true
                  

          });
      } else if ($(this).attr("id") === "byBP") {
        const router = context.$router;

        context.dataloaded = true;
        
      } 
    });

    $("#advance-daterange span").data("context", this.context);

    $("#advance-daterange span").html(
      moment()
        .subtract("days", 29)
        .format("MMMM D, YYYY") +
        " - " +
        moment().format("MMMM D, YYYY")
    );

    $("#advance-daterange").daterangepicker(
      {
        format: "MM/DD/YYYY",
        startDate: moment().subtract(29, "days"),
        endDate: moment(),
        minDate: "01/01/2001",
        maxDate: "12/31/2099",
        dateLimit: { days: 5000 },
        showDropdowns: true,
        showWeekNumbers: true,
        timePicker: false,
        timePickerIncrement: 1,
        timePicker12Hour: true,
        ranges: {
          Today: [moment(), moment()],
          Yesterday: [
            moment().subtract(1, "days"),
            moment().subtract(1, "days")
          ],
          "Last 7 Days": [moment().subtract(6, "days"), moment()],
          "Last 30 Days": [moment().subtract(29, "days"), moment()],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
          "Last Month": [
            moment()
              .subtract(1, "month")
              .startOf("month"),
            moment()
              .subtract(1, "month")
              .endOf("month")
          ]
        },
        opens: "right",
        drops: "down",
        buttonClasses: ["btn", "btn-sm"],
        applyClass: "btn-primary",
        cancelClass: "btn-default",
        separator: " to ",
        locale: {
          applyLabel: "Submit",
          cancelLabel: "Cancel",
          fromLabel: "From",
          toLabel: "To",
          customRangeLabel: "Custom",
          daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
          monthNames: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
          ],
          firstDay: 1
        }
      },
      function(start, end, label) {
        const context = $("#advance-daterange span").data("context");

        context.startDate = start.format("D MMMM YYYY");
        context.endDate = end.format("D MMMM YYYY");

        $("#advance-daterange span").html(
          start.format("D MMMM YYYY") + " - " + end.format("D MMMM YYYY")
        );

        context.loadgeneratepaymentHistory(context);
        
      }
    );

    this.startDate = $('#advance-daterange').data('daterangepicker').startDate.format("D MMMM YYYY");
    this.endDate = $('#advance-daterange').data('daterangepicker').endDate.format("D MMMM YYYY");


    this.paymentsloaded = false;

    this.loadgeneratepaymentHistory(this);


     $("#advance-daterange-billpaymentssummary span").data("context", this.context);

    $("#advance-daterange-billpaymentssummary span").html(
      moment()
        .subtract("days", 29)
        .format("MMMM D, YYYY") +
        " - " +
        moment().format("MMMM D, YYYY")
    );

    $("#advance-daterange-billpaymentssummary").daterangepicker(
      {
        format: "MM/DD/YYYY",
        startDate: moment().subtract(1, "days"),
        endDate: moment(),
        minDate: "01/01/2001",
        maxDate: "12/31/2099",
        dateLimit: { days: 5000 },
        showDropdowns: true,
        showWeekNumbers: true,
        timePicker: false,
        timePickerIncrement: 1,
        timePicker12Hour: true,
        ranges: {
          Today: [moment(), moment()],
          Yesterday: [
            moment().subtract(1, "days"),
            moment().subtract(1, "days")
          ],
          "Last 7 Days": [moment().subtract(6, "days"), moment()],
          "Last 30 Days": [moment().subtract(29, "days"), moment()],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
          "Last Month": [
            moment()
              .subtract(1, "month")
              .startOf("month"),
            moment()
              .subtract(1, "month")
              .endOf("month")
          ]
        },
        opens: "right",
        drops: "down",
        buttonClasses: ["btn", "btn-sm"],
        applyClass: "btn-primary",
        cancelClass: "btn-default",
        separator: " to ",
        locale: {
          applyLabel: "Submit",
          cancelLabel: "Cancel",
          fromLabel: "From",
          toLabel: "To",
          customRangeLabel: "Custom",
          daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
          monthNames: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
          ],
          firstDay: 1
        }
      },
      function(start, end, label) {

        //alert("test")

        const context = $("#advance-daterange-billpaymentssummary span").data("context");

        context.startDate = start.format("D MMMM YYYY");
        context.endDate = end.format("D MMMM YYYY");

        $("#advance-daterange-billpaymentssummary span").html(
          start.format("D MMMM YYYY") + " - " + end.format("D MMMM YYYY")
        );

        context.loadgeneratebillpaymentHistory(context);
        
      }
    );

    this.startDate = $('#advance-daterange-billpaymentssummary').data('daterangepicker').startDate.format("D MMMM YYYY");
    this.endDate = $('#advance-daterange-billpaymentssummary').data('daterangepicker').endDate.format("D MMMM YYYY");

    //  this.getByTaxiAssId(this.context);
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

