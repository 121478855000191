<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->

    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'TaxiVIP'"
      :ActiveMenu="'ViewCommuters'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="VIP Commuters"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="TaxiVIP"
        breadcrumbitem3="Commuter Profile"
      />
      <!-- end page-header -->
      <div class="row">
        <!-- begin col-3 -->
        <div class="col-lg-3 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-ios-card"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">NO OF CARDS</div>
              <div class="stats-number">{{GETCOMMUTERBYID.numberofcards }}</div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
          <!-- begin col-3 -->
        <div class="col-lg-3 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-ios-hourglass"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">TOTAL BALANCE</div>
              <div class="stats-number">{{GETCOMMUTERBYID.totalbalance | newcurrencywithSymbol}}</div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
        <!-- begin col-3 -->
        <!-- <div class="col-lg-3 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-logo-usd"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">AVAILABLE BALANCE</div>

              <div
                class="stats-number"
              ></div>
            </div>
          </div>
        </div> -->
        <!-- end col-3 -->
      </div>

      <!-- begin #Main Body Template -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <div class="panel panel-inverse">
            <!-- begin fieldset -->
            <fieldset>
              <p class="text-center h4 mb-4">Commuter Details</p>
              <div class="row">
                <!-- begin col-6 -->

                <!-- begin form-group -->
                <div class="col-lg-6">

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Firstname</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="GETCOMMUTERBYID.firstname"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Surname</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="GETCOMMUTERBYID.surname"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Mobile Number</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="GETCOMMUTERBYID.mobile_number"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                </div>

                <!-- Begin Second Row -->
                <div class="col-lg-6">
                  <!-- begin form-group -->
                  

                </div>
              </div>
            </fieldset>
            <!-- end fieldset -->
          </div>
        </div>
        <!-- end col-12 -->
      </div>
      <div class="btn-row">
        
      </div>
      <br />

      <!-- begin panel -->
      <div class="panel panel-inverse panel-with-tabs" data-sortable-id="ui-unlimited-tabs-2">
        <!-- begin panel-heading -->
        <div class="panel-heading p-0">
          <div class="panel-heading-btn m-r-10 m-t-10">
            <a
              href="javascript:;"
              class="btn btn-xs btn-icon btn-circle btn-inverse"
              data-click="panel-expand"
            >
              <i class="fa fa-expand"></i>
            </a>
          </div>
          <!-- begin nav-tabs -->
          <div class="tab-overflow">
            <ul class="nav nav-tabs-inverse">
              <li class="nav-item prev-button">
                <a href="javascript:;" data-click="prev-tab" class="text-inverse nav-link">
                  <i class="fa fa-arrow-left"></i>
                </a>
              </li>
              <li class="nav-item">
                <a href="#nav-tab2-1" data-toggle="tab" class="nav-link">Cards</a>
              </li>
              
              <li class="nav-item next-button">
                <a href="javascript:;" data-click="next-tab" class="text-inverse nav-link">
                  <i class="fa fa-arrow-right"></i>
                </a>
              </li>
            </ul>
          </div>
          <!-- end nav-tabs -->
        </div>
        <!-- end panel-heading -->
        <!-- begin tab-content -->
        <div class="tab-content">
          <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab2-1">
            <!-- Start panel-body -->
            <div class="panel-toolbar">
              <div class="btn-group m-r-5">
               
                  <button
                  type="button"
                  class="btn btn-sm btn-danger width-100"
                  v-if="ISVIEWTRANSACTIONS"
                  @click="ClickViewCards(context)"
                >View Cards</button>
              </div>
            </div>
            <div class="panel-body">
              <table id="data-table-defaultcard" v-if="!ISVIEWTRANSACTIONS" class="table table-striped table-bordered width-full">
              <thead>
                <tr>
                  <th width="1%" data-orderable="false"></th>
                  <th class="text-nowrap">Card Number</th>
                  <th class="text-nowrap">Card Id</th>
                  <th class="text-nowrap">Balance</th>
                  <th class="text-nowrap">Status</th>
                  <th class="text-nowrap">Action</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(item,index) in GETCOMMUTERVIPCARDS"
                  v-bind:key="item.id"
                  :class="index & 1 ? 'odd gradeA' : 'even'"
                >
                  <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                  <td>{{item.cardinventory_id}}</td>
                  <td>{{item.cardnumber}}</td>
                  <td>{{item.balance | currencywithSymbol}}</td>
                  <td>
                    <span
                      class="badge f-s-12"
                      v-bind:class="{ 'badge-success': !item.blocked, 'badge-danger':item.blocked  }"
                    >{{item.is_active && !item.blocked ? 'Active' : 'Blocked' }}</span>
                  </td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-sm btn-secondary width-100"
                      @click="ClickViewTransactions(context,item)"
                    >
                    <v-wait :for="'Transactions-' + item.id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Transactions
                        </v-wait>
                        </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-danger width-150"
                      v-if="!item.blocked"
                      @click="ClickBlockCard(context,item)"
                    >Block Card</button>
                    <button
                      type="button"
                      class="btn btn-sm btn-success width-150"
                      v-if="item.blocked"
                      @click="ClickUnBlockCard(context,item)"
                    >UnBlocked Card</button>
                  </td>
                </tr>
              </tbody>
            </table>
            <table id="data-table-defaulttrans" v-if="ISVIEWTRANSACTIONS" class="table table-striped table-bordered width-full">
              <thead>
                <tr>
                  <th width="1%" data-orderable="false"></th>
                  <th class="text-nowrap">Description</th>
                  <th class="text-nowrap">Amount</th>
                  <th class="text-nowrap">Date</th>
                  <th class="text-nowrap">Balance</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(item,index) in GETCOMMUTERCARDTRANSACTIONS"
                  v-bind:key="item._id"
                  :class="index & 1 ? 'odd gradeA' : 'even'"
                >
                  <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                  <td>{{item._transaction_reference}}</td>
                  <td>{{item._transaction_amount | newcurrencywithSymbol}}</td>
                  <td>{{item._transdate}} {{item._transtime}}</td>
                  <td>{{item._remaning_balance | currencywithSymbol}}</td>
                </tr>
              </tbody>
            </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end tab-pane -->
        </div>
        <!-- end tab-content -->
      </div>
      <!-- end panel -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>


	<!-- The template to display files available for upload -->

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import AudioVideoControl from "@/components/common/AudioVideoControl.vue";
import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("taxivip");

export default {
  name: "COMMUTERPROFILE",
  data: function() {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      accountNumber: "",
      id: this.$route.params.id,
      GETUPLOADEDFILES: [],
      updatedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      skip: 0,
      len: 1000,
      GETCOMMUTERVIPCARDS: [],
      ISVIEWTRANSACTIONS:false,
      GETCOMMUTERCARDTRANSACTIONS: [],
      GETCOMMUTERBYID: {
        firstname: "",
        surname: "",
        mobile_number: ""
      },
      GETCOMMUTERCARDBYID: {
        cardinventory_id: 0,
        id: "",
        cardnumber: "",
        is_active: false,
        blocked: false,
        balance: 0
      },
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    AudioVideoControl
  },

  computed: {
    ...mapGetters(["GETCOMMUTERROUTES"]),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions([
      "getall",
      "getbyid"
    ]),

    fileadded: function(data) {
      console.log("Header:fileadded");
      console.log(data);
      //this.$emit('init')

      const context = $("#content").data("context");
      context.GETUPLOADEDFILES = context.$store.getters["fileupload/GETALL"];
    },

    getbyid: (context, id) => {
      context.$store
        .dispatch("taxivip/getById", {
          router: context.$router,
          id
        })
        .then(data => {

          context.GETCOMMUTERBYID = data[0]
          console.log("this is the commuter: ", data);
         // context.getCommuterCardById(context,context.id);
          
        })
        .catch(err => {
          console.log("commuter details error: ", err);
        });
    },

    ClickViewCards: (context) => {
      context.ISVIEWTRANSACTIONS = false;
    },
    ClickUnBlockCard: (context, item) => {
      context.$Notify.YesNo(false, { context, item }).then(value => {
        if (value) {
          value.context.$store
        .dispatch("taxivip/unblockCommuterCard", {
          router: context.$router,
          AddModel:{
            commutercard_id:value.item.id,
            updated_by: value.context.updatedby
          }
        })
        .then(data => {
          value.item.blocked = false;
        })
        .catch(err => {
          console.log("commuter card error: ", err);
        }).finally(() => {
          // $("#data-table-defaultcard").DataTable({
          //   responsive: false
          // });
        });
      }
    });
    },
    ClickBlockCard: (context, item) => {
      context.$Notify.YesNo(false, { context, item }).then(value => {
        if (value) {
          value.context.$store
        .dispatch("taxivip/blockCommuterCard", {
          router: context.$router,
          AddModel:{
            commutercard_id:value.item.id,
            updated_by: value.context.updatedby
          }
        })
        .then(data => {
          value.item.blocked = true;
        })
        .catch(err => {
          console.log("commuter card error: ", err);
        }).finally(() => {
          // $("#data-table-defaultcard").DataTable({
          //   responsive: false
          // });
        });
      }
    });
    },
    getCommuterCardsById: (context, id) => {
      context.$store
        .dispatch("taxivip/getcommuterCardsById", {
          router: context.$router,
          id
        })
        .then(data => {
          context.GETCOMMUTERVIPCARDS = data;
        })
        .catch(err => {
          console.log("commuter card error: ", err);
        }).finally(() => {
          // $("#data-table-defaultcard").DataTable({
          //   responsive: false
          // });
        });
    },

    ClickViewTransactions: (context, item) => {
      context.$wait.start("Transactions-" + item.id);
      context.$store
        .dispatch("taxivip/getCommuterCardTransactionsById", {
          id: item.cardnumber,
          skip: context.skip, 
          len: context.len,
          router: context.$router,
        })
        .then(data => {
          context.GETCOMMUTERCARDTRANSACTIONS = data
          //console.log("commuter transactions: ", data);
          context.ISVIEWTRANSACTIONS = true;
        })
        .catch(err => {
          console.log("commuter transactions error: ", err);
        }).finally(() => {
          context.$wait.end("Transactions-" + item.id);
          // $("#data-table-defaulttrans").DataTable({
          //   responsive: false
          // });
        });
    },
  },
  beforeMount() {
    this.getbyid(this.context, this.id);
    //this.getCommuterCardTransactionsById(this.context,this.id);
    this.getCommuterCardsById(this.context,this.id);
  },
  mounted: function() {
    App.init();
  }
};

</script>

	

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>